export default class LocalStorage {
  protected readonly key: string;

  static isSupported = true;

  private static initialized = false;

  constructor(key: string) {
    this.key = key;

    if (!LocalStorage.initialized) {
      this.init();
    }
  }

  private init(): void {
    LocalStorage.isSupported = LocalStorage.checkAvailability();
    LocalStorage.initialized = true;
  }

  setItem(value: string): void {
    if (LocalStorage.isSupported) {
      localStorage.setItem(this.key, value);
    }
  }

  getItem(): string | null {
    if (LocalStorage.isSupported) {
      return localStorage.getItem(this.key);
    }

    return null;
  }

  removeItem(): void {
    if (LocalStorage.isSupported) {
      localStorage.removeItem(this.key);
    }
  }

  protected static checkAvailability(): boolean {
    try {
      const test = 'test';
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch {
      return false;
    }
  }
}
