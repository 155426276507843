import { store } from '@risingstack/react-easy-state';

type InvoicingState = {
  isSubmitting: boolean;
  companyNameFilled: boolean;
  invoiceId?: string;
  price?: number;
  gettingCompanies?: boolean;
  // biome-ignore lint/suspicious/noExplicitAny:
  companyOptions: Record<any, any>[];
};

const invoicingState: InvoicingState = store({
  isSubmitting: false,
  companyNameFilled: false,
  gettingCompanies: false,
  companyOptions: [],
});

export default invoicingState;
