import { CustomEvents } from 'enums';
import { appState } from 'store';

import { ga4MeasurementId } from './constants';

function gtagSetup(): void {
  if (window.gtag) {
    window.gtag('config', ga4MeasurementId, {
      user_id: appState.storeId,
      cookie_domain: appState.storeId,
      cookie_flags: 'SameSite=None;Secure',
      send_page_view: false,
    });
  }
}

type FireCustomEvent = {
  event: CustomEvents;
  params?: {
    // biome-ignore lint/suspicious/noExplicitAny:
    [key: string]: any;
  };
};

function fireCustomGtagEvent({ event, params = {} }: FireCustomEvent): void {
  if (window.gtag) {
    window.gtag('event', event, { event_category: 'Admin Activity', ...params });
  }
}

function pageViewEvent(pagePath: string): void {
  if (window.gtag) {
    window.gtag('event', 'page_view', {
      page_title: document.title,
      page_location: document.location.href,
      page_path: pagePath,
      send_to: ga4MeasurementId,
    });
  }
}

export { fireCustomGtagEvent, gtagSetup, pageViewEvent };
