// @ts-strict-ignore
import { RapidSearchPlans, ShopifyPlanName, ShopifyPriceGroup, ShopifyPricingEndpointType } from 'enums';
import i18next from 'i18next';
import { appState } from 'store';
import { ShopifyPlan, SubscriptionPlan } from 'types.d';
import { checkIfPaidPlan } from 'utils/statelessFunctions';
import { getCurrentPlanDetails, getPlanDetails } from '../../../utils/billing/shopify/shopify';

import pricingState from '../Pricing.store';
import { newPricingApiEndpoint, shopifyPlans } from '../../../utils/billing/shopify/constants';
import { GetSuggestedPlan } from './type';

export function getSuggestedPlan({ numberOfProducts, estimatedMonthlySessions }: GetSuggestedPlan) {
  const suggestedPlansList = getSuggestedPlansList();

  for (const plan of suggestedPlansList) {
    const { maxNumberOfProducts, maxNumberOfSessions } = plan;
    const isBelowProductLimit = isBelowLimit(numberOfProducts, maxNumberOfProducts);
    const isBelowSessionsLimit = isBelowLimit(estimatedMonthlySessions, maxNumberOfSessions);

    if (isBelowProductLimit && isBelowSessionsLimit) {
      return plan;
    }
  }

  return getPlanDetails(RapidSearchPlans.SHOPIFY_UNLIMITED);
}

function getSuggestedPlansList() {
  const currentPlan = getCurrentPlanDetails();
  const isShopifyPlusUser = appState.settings.plan_name === ShopifyPlanName.SHOPIFY_PLUS;

  return pricingState.shopifyVisiblePlans.filter(({ id, price, isShopifyPlus }) => {
    const isPaidPlan = checkIfPaidPlan(id);
    const isNotCheaperPlan = price > currentPlan.price;
    const isShopifyPlusCompatible = !isShopifyPlusUser || isShopifyPlus;

    return isPaidPlan && isNotCheaperPlan && isShopifyPlusCompatible;
  });
}

export function isShopifyUnlimited(planId: RapidSearchPlans): boolean {
  return planId === RapidSearchPlans.SHOPIFY_UNLIMITED;
}

function isBelowLimit(currentValue: number, maximumValue: number): boolean {
  return currentValue <= maximumValue;
}

export function isShopifyFreePlan(planId: RapidSearchPlans) {
  return planId === RapidSearchPlans.SHOPIFY_FREE_1;
}

export const isPlanUpgrade = (selectedPlanId: ShopifyPlan) => {
  const currentPlanPrice = getShopifyPlanPrice(appState.settings.current_plan as ShopifyPlan);
  const selectedPlanPrice = getShopifyPlanPrice(selectedPlanId);

  return currentPlanPrice < selectedPlanPrice;
};

// biome-ignore lint/suspicious/noExplicitAny:
export const translate = (text: string, translationOptions?: any) => i18next.t(`pricing.new_pricing.${text}`, translationOptions);

export const translateOldPricing = (text: string) => i18next.t(`pricing.${text}`);

export const getPlanIndex = (planId: ShopifyPlan) => pricingState.shopifyVisiblePlans.findIndex(({ id }) => planId === id);

export function getShopifyPricingEndpoint(type: ShopifyPricingEndpointType) {
  return `${newPricingApiEndpoint}/${type}`;
}

export function generateSliderSessionsSteps(isMobile: boolean): Record<number, string> {
  return pricingState.shopifyVisiblePlans.reduce((steps, { maxNumberOfSessions }, key): Record<number, string> => {
    steps[key] = generateSliderSessionsStepText(maxNumberOfSessions, isMobile);

    return steps;
  }, {});
}

function generateSliderSessionsStepText(sessions: number, isMobile: boolean): string {
  const isUnlimited = sessions === 0;

  if (isUnlimited) {
    return getUnlimitedSliderText(isMobile);
  }

  return isMobile ? `${sessions / 1000}K` : sessions.toLocaleString();
}

function getUnlimitedSliderText(isMobile): string {
  return isMobile ? '∞' : translate('unlimited');
}

export function getShopifyPlanPrice(planId: SubscriptionPlan) {
  return shopifyPlans[planId]?.price || 0;
}

export function getShopifyVisiblePlans() {
  const priceGroup = getShopifyPriceGroup();
  return Object.values(shopifyPlans).filter((plan) => plan.group.includes(priceGroup));
}

function getShopifyPriceGroup(): ShopifyPriceGroup {
  if (appState.campaign.isActive) {
    return ShopifyPriceGroup.BF_2023;
  }

  return ShopifyPriceGroup.NORMAL;
}
