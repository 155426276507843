import { RestrictedFeatures } from '../enums';

const standardParams = [
  'shop',
  'shopname',
  'test',
  'hmac',
  'timestamp',
  'embedded',
  'host',
  'locale',
  'session',
  'code',
  'posthog',
];
const restrictedFeaturesParams = Object.values(RestrictedFeatures);
const supportedUrlParams = standardParams.concat(restrictedFeaturesParams);

function generateUrlWithParams(path: string): string {
  const { search } = window.location;

  const isNotPathName = path[0] !== '/';

  if (isNotPathName) {
    path = window.location.pathname + path;
  }

  const hostURL = `https://example.com`;
  const urlString = hostURL + path;
  const url = new URL(urlString);
  const newParams = new URLSearchParams(search);

  supportedUrlParams.forEach((param) => {
    const paramValue = newParams.get(param);

    if (paramValue) {
      url.searchParams.set(param, paramValue);
    }
  });

  return url.toString().replace(hostURL, '');
}

export { generateUrlWithParams };
