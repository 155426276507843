import authState from '../authentication/store';
import { Settings } from '../types.d';
import { adminDomain } from './constants';

async function getStoreSettings(storeId: string): Promise<Settings> {
  const { email } = authState;
  const url = new URL(`${adminDomain}/api/settings/${storeId}`);

  if (email) {
    url.searchParams.append('email', email);
  }

  const response = await fetch(url.toString());

  return response.json();
}

export { getStoreSettings };
