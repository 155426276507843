// @ts-strict-ignore
import { RapidSearchPlans } from 'enums';
import moment from 'moment';
import { appState } from 'store';
import { CampaignInfo, SetShoprenterCampaignData } from 'types.d';

import {
  defaultCampaignValues,
  getShopifyBlackFridayCampaign,
  getShoprenterBirthdayCampaign,
  shopifyBlackFridayStartDate,
  shoprenterExpoCampaign,
  shoprenterSpringCampaignTrial,
} from './campaigns.contants';

export function setSpringCampaignData(): void {
  const startDate = moment('2024-09-02').startOf('day');
  const userHasStarterPlan = hasAllowedPaidPlan(shoprenterSpringCampaignTrial.allowedPaidPlans);

  setShoprenterCampaignData({
    startDate: startDate,
    campaign: getShoprenterBirthdayCampaign(appState.settings.campaign_end_date, userHasStarterPlan),
    activationCondition: !appState.isPaid || userHasStarterPlan,
  });
}

function hasAllowedPaidPlan(allowedPaidPlans: RapidSearchPlans[]): boolean {
  return allowedPaidPlans.includes(appState.settings.current_plan);
}

function _setExpoCampaignData(): void {
  setShoprenterCampaignData({
    startDate: moment('2024-02-29T23:00:00.000Z'),
    campaign: shoprenterExpoCampaign,
    activationCondition: appState.settings.expo_discount && !appState.isPaid,
  });
}

function setShoprenterCampaignData({ startDate, campaign, activationCondition }: SetShoprenterCampaignData): void {
  const { shoprenter_public_settings, force_campaign } = appState.settings;
  const campaignIsActive = (activationCondition && moment().isBetween(startDate, campaign.end)) || force_campaign;

  if (!campaignIsActive || shoprenter_public_settings?.allow_old_plans) {
    return;
  }

  appState.campaign = campaign;
}

export function getShopifyCampaignData(campaignEndDate: Date): CampaignInfo {
  if (appState.isPaid || !isShopifyBlackFridayCampaignActive(campaignEndDate)) {
    return defaultCampaignValues;
  }

  return getShopifyBlackFridayCampaign(campaignEndDate);
}

function isShopifyBlackFridayCampaignActive(campaignEndDate: Date) {
  return moment().isBetween(shopifyBlackFridayStartDate, campaignEndDate);
}
