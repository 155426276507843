// @ts-strict-ignore
import shoprenterPlans from 'pages/Pricing/ShoprenterPlanCard/shoprenter.plans';
import { calculateOverUsePercentage } from 'utils/statelessFunctions';

import { appState } from '../../store';
import { Settings } from '../../types.d';
import { getCurrentPlanDetails, getPlanDetails } from './shopify/shopify';

function getSearchStats(): Settings['search_stats_summary'] {
  const searchStatsSummary = appState.settings.search_stats_summary;

  if (appState.isDemo) {
    return {
      updated_at: new Date(),
      billingPeriod: searchStatsSummary.billingPeriod,
      limitExceeded: false,
      limitIsNear: false,
      monthlyEstimatedSearches: 7000,
      monthlyEstimatedSessions: 2000,
      searchRevenue: 6000,
      searchesCount: 3000,
      usdSearchRevenue: 6000,
      sessions: 2000,
    };
  }

  return searchStatsSummary;
}

export function setPlanLimitationStats(): void {
  const searchStats = getSearchStats();

  const {
    searchesCount,
    searchRevenue,
    limitExceeded,
    limitExceededDate,
    billingPeriod,
    usdSearchRevenue,
    monthlyEstimatedSearches,
    monthlyEstimatedSessions,
    sessions,
  } = searchStats;

  appState.limitExceeded = limitExceeded;

  appState.billing.dates = {
    startDate: billingPeriod.start,
    endDate: billingPeriod.end,
    nextBill: billingPeriod.nextBill,
    limitExceededDate,
  };

  appState.billing.data = {
    usdSearchRevenue,
    monthlyEstimatedSessions,
    searchesCount,
    searchRevenue,
    sessions,
  };

  appState.estimatedSearchesPerMonth = monthlyEstimatedSearches;
  const currentPlanDetails = getCurrentPlanDetails();
  appState.planEstimatedSessionUsagePercent = (monthlyEstimatedSessions / currentPlanDetails.maxNumberOfSessions) * 100;
  appState.planOverUsePercentage = getPlanOverUsePercentage();
}

function getPlanOverUsePercentage(): number {
  const { isShopify, isShoprenter, isPaid } = appState;
  const { subscription_info, search_stats_summary, current_plan } = appState.settings;
  const { monthly_sessions, monthly_searches } = subscription_info;
  const { monthlyEstimatedSessions, monthlyEstimatedSearches } = search_stats_summary;

  if (isShopify) {
    const limit = isPaid ? monthly_sessions : getPlanDetails(current_plan).maxNumberOfSessions;
    return calculateOverUsePercentage(monthlyEstimatedSessions, limit);
  }

  if (isShoprenter) {
    const limit = isPaid ? monthly_searches : getShoprenterMonthlySearches(current_plan);

    return calculateOverUsePercentage(monthlyEstimatedSearches, limit);
  }

  return 0;
}

function getShoprenterMonthlySearches(planId: string): number {
  const planDetails = shoprenterPlans[planId];
  return planDetails ? planDetails.monthly_searches : 0;
}
