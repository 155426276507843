// @ts-strict-ignore
import moment from 'moment';
import { appState } from 'store';
import { RapidSearchPlans } from '../../../enums';
import { shopifyPlans, trialPlan } from './constants';
import { ShopifyPlan, ShopifyPlanDetails } from '../../../types.d';

export function getActiveDays() {
  const { limitExceededDate, startDate } = appState.billing.dates;
  return moment(limitExceededDate).diff(moment(startDate), 'days');
}

export function getCurrentPlanDetails(): ShopifyPlanDetails {
  const { settings, isUnlimitedSessions, isPaid } = appState;
  const { current_plan, subscription_info } = settings;

  const subscriptionIsInactive = !isPaid;
  const defaultPlanDetails = getPlanDetails(current_plan);

  if (subscriptionIsInactive) {
    return defaultPlanDetails;
  }

  return {
    id: subscription_info.plan as ShopifyPlan,
    planName: subscription_info.name || defaultPlanDetails.planName,
    price: subscription_info.price,
    maxNumberOfSessions: subscription_info.monthly_sessions ?? defaultPlanDetails.maxNumberOfSessions,
    maxNumberOfProducts: subscription_info.monthly_products ?? defaultPlanDetails.maxNumberOfProducts,
    isShopifyPlus: isUnlimitedSessions || defaultPlanDetails.isShopifyPlus,
  } as ShopifyPlanDetails;
}

export function getPlanDetails(planId: RapidSearchPlans): ShopifyPlanDetails {
  const planDetails = shopifyPlans[planId];

  if (planDetails) {
    return planDetails;
  }

  if (planId === RapidSearchPlans.SHOPIFY_TRIAL) {
    return trialPlan;
  }

  return getPlanDetails(RapidSearchPlans.SHOPIFY_FREE_1);
}
