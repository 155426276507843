// @ts-strict-ignore
import { store } from '@risingstack/react-easy-state';
import { AdminRoles } from 'enums';

export type AuthAction = 'signup' | 'login' | 'reset_password';

type AuthState = {
  isLoggedIn: boolean;
  storeIds: string[];
  isInitializing: boolean;
  loading: boolean;
  newStore: {
    modalVisible: boolean;
    loading: boolean;
    error?: string;
  };
  requiresAuthentication: boolean;
  error?: string;
  email: string;
  role: AdminRoles;
};

const authState: AuthState = store({
  isLoggedIn: false,
  requiresAuthentication: true,
  isInitializing: true,
  storeIds: [],
  newStore: {
    modalVisible: false,
    loading: false,
  },
  loading: false,
  email: null,
  role: AdminRoles.ADMIN,
});

export default authState;
