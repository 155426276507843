// import LogRocket from 'logrocket';
// import { isLiveEmbeddedAdmin } from './utils/constants';
// import { appState } from './store';
//
// export function identifyLogRocketUser(): void {
//   if (!isLiveEmbeddedAdmin) {
//     return;
//   }
//
//   LogRocket.init('dnalxv/rapid-search', {
//     mergeIframes: true,
//   });
//
//   LogRocket.identify(appState.storeId, {
//     name: appState.storeId,
//     email: appState.settings.contact_email,
//     current_plan: appState.settings.current_plan,
//   });
// }

export function addNumberFormat(): void {
  Number.prototype.format = function (n, x): string {
    const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? '\\.' : '$'})`;
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$& ');
  };
}
