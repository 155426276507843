import i18n from 'i18next';
import en from 'locales/en.json';
import hu from 'locales/hu.json';
import { initReactI18next } from 'react-i18next';

import { appState } from './store';

export async function initializeLang(lang: string): Promise<void> {
  await i18n.use(initReactI18next).init({
    resources: {
      en: { translation: en },
      hu: { translation: hu },
    },
    lng: lang,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

  appState.language = lang;
}
