import { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { appState } from '../../store';
import { generateUrlWithParams } from '../../utils/generateUrlWithParams';
import { shakeSaveNotification } from '../../utils/notifications/notifications';

const History: FC = () => {
  const history = useHistory();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const push = useCallback((path: string, state): void => {
    history.push(generateUrlWithParams(path), state);
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const replace = useCallback((path: string, state): void => {
    history.replace(generateUrlWithParams(path), state);
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const goBack = useCallback((): void => {
    if (!appState.unsavedChanges) {
      history.goBack();
      return;
    }

    shakeSaveNotificationAndNavbar();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    appState.history = { ...history, push, replace, goBack };
  }, []);

  return null;
};

function shakeSaveNotificationAndNavbar(): void {
  if (appState.isShopifyEmbedded) {
    appState.triggerShopifyNavbarLeaveConfirmation?.();
  } else {
    shakeSaveNotification();
  }
}

export default History;
