interface SaveBarElement extends HTMLElement {
  show: () => void;
  hide: () => void;
}

export default class ContextualSaveBar {
  public static readonly discardButtonId = 'shopify-contextual-discard-button';
  public static readonly saveButtonId = 'shopify-contextual-save-button';
  public static readonly barId = 'shopify-contextual-save-bar';

  private static get saveBar(): SaveBarElement {
    return document.getElementById(this.barId) as SaveBarElement;
  }

  public static show(): void {
    this.saveBar.show();
  }

  public static hide(): void {
    this.saveBar.hide();
  }
}
