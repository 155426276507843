import { WebshopEngine } from 'enums';
import { appState } from 'store';
import { isDev } from 'utils/constants';
import { gtagSetup } from 'utils/gtag';

import authState from '../authentication/store';
import { initializeStoreSettings } from './initializeHelper';

const initializeApp = async (storeId: string): Promise<void> => {
  try {
    const notShoprenter = !storeId?.includes(WebshopEngine.SHOPRENTER);
    const notShopify = !storeId?.includes(WebshopEngine.SHOPIFY);

    authState.requiresAuthentication = !storeId || (notShoprenter && notShopify && !isDev) || appState.isMarketplaceAdmin;

    if (authState.requiresAuthentication) {
      const authentication = await import(/* webpackChunkName: "authentication" */ '../authentication/authentication');
      await authentication.authenticateUser(storeId);
      return;
    }

    await initializeStoreSettings(storeId);

    gtagSetup();

    appState.initializing = false;
  } catch (e) {
    console.log(e);
    appState.initializationError = true;
  }
};

export default initializeApp;
