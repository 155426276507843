// @ts-strict-ignore
import { appState } from '../store';
import { PartialSettings } from '../types.d';
import PosthogHandler from './posthog/posthog';

function onFirstSyncComplete(): void {
  showFirstSyncDoneNotifications();
}

function showFirstSyncDoneNotifications(): void {
  if (!appState.isShopify) {
    return;
  }

  appState.settings.first_sync_info.modal_shown = true;
  appState.syncDoneNotificationVisible = true;

  appState.saveSettingsAndResetAppState({
    first_sync_info: {
      modal_shown: true,
    },
  } as PartialSettings);
  PosthogHandler.triggerSyncProgressBarFinishedEvent();
}

export { onFirstSyncComplete };
