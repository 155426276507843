// @ts-strict-ignore
import moment from 'moment';
import { appState } from 'store';
import { getCustomDate, getStatisticsDateInfo } from 'utils/statelessFunctions';
import { dateRanges } from 'utils/statistics/constants';

export function initializeStatisticsRange() {
  const isCustom = appState.settings.statisticsDateRange?.split('/').length === 2;

  if (isCustom) {
    const [startString, endString] = appState.settings.statisticsDateRange?.split('/') || [];
    const { startDate, endDate } = getCustomDate(startString, endString);

    appState.statisticsDate = {
      ...appState.statisticsDate,
      ...getStatisticsDateInfo({
        id: `${startDate} - ${endDate}`,
        endDate: moment(endString),
        startDate: moment(startString).startOf('day'),
      }),
      isCustom: true,
    };

    return;
  }

  const statisticsDate = dateRanges[appState.settings.statisticsDateRange];

  if (statisticsDate) {
    appState.statisticsDate = {
      ...appState.statisticsDate,
      ...getStatisticsDateInfo(statisticsDate),
    };
  }
}
