import { view } from '@risingstack/react-easy-state';
import React, { FC, lazy, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { appState } from 'store';
import { pageViewEvent } from 'utils/gtag';
import PosthogHandler from 'utils/posthog/posthog';

import { addNumberFormat } from './Admin.functions';
import authState from './authentication/store';
import Loading from './components/UI/Loading/Loading';
import initializeApp from './initialize/initializeApp';
import { getStoreId } from './utils/statelessFunctions';

const ErrorPage = lazy(() => import(/* webpackChunkName: "ErrorPage" */ './pages/ErrorPage/ErrorPage'));
const LoggedOut = lazy(() => import(/* webpackChunkName: "LoggedOut" */ './components/LoggedOut/LoggedOut'));
const MarketplaceAdmin = lazy(
  () => import(/* webpackChunkName: "MarketplaceAdmin" */ './components/MarketplaceAdmin/MarketplaceAdmin'),
);
const GeneralAdmin = lazy(() => import(/* webpackChunkName: "GeneralAdmin" */ './components/GeneralAdmin/GeneralAdmin'));

const Admin: FC = () => {
  const { pathname, search } = useLocation();

  const storeId = getStoreId();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    (async (): Promise<void> => {
      await initializeApp(storeId);
      if (!authState.requiresAuthentication) {
        // identifyLogRocketUser();
        PosthogHandler.initPosthog();
      }
    })();

    addNumberFormat();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    pageViewEvent(pathname);
  }, [pathname, search]);

  if (appState.initializing) {
    return <Loading />;
  }

  if (appState.initializationError) {
    return <ErrorPage title="settings_error.failed" buttonTitle="settings_error.try_again" onClick={window.location.reload} />;
  }

  if (authState.requiresAuthentication && !authState.isLoggedIn) {
    return <LoggedOut />;
  }

  if (appState.isMarketplace) {
    return <MarketplaceAdmin />;
  }

  return <GeneralAdmin pathname={pathname} search={search} />;
};

export default view(Admin);
