// @ts-strict-ignore
import moment from 'moment';
import { shopifyPlans } from 'utils/billing/shopify/constants';
import { DisabledFeatures, GetAdminUrl } from 'types.d';
import { getIsShopifyCustomPlan } from 'utils/statelessFunctions';

import { RapidSearchPlans, ShoprenterPriceGroup, WebshopEngine } from '../enums';
import { appState } from '../store';
import LocalStorageWithStoreId from '../utils/LocalStorage/LocalStorageWithStoreId';
import {
  backendBasePaths,
  shopifyAppUrl,
  shoprenterAppUrl,
  shoprenterStarterPlans,
  subscriptionLocalStorageKey,
  trialPlans,
} from '../utils/constants';
import { getStoreSettings } from '../utils/getStoreSettings';
import { onFirstSyncComplete } from '../utils/onFirstSyncComplete';

const featuresExclusive: Record<string, RapidSearchPlans[]> = {
  merchandising: shoprenterStarterPlans,
  shoprenter_badges: shoprenterStarterPlans,
  attribute_filters: shoprenterStarterPlans,
};

function getShoprenterPriceGroup(): ShoprenterPriceGroup {
  if (appState.campaign.isActive) {
    return ShoprenterPriceGroup.BF_2021;
  }

  if (appState.settings.shoprenter_public_settings?.allow_old_plans) {
    return ShoprenterPriceGroup.BEFORE_2022_11_26;
  }

  return ShoprenterPriceGroup.AFTER_2022_11_26;
}

const getDisabledFeatures = (): DisabledFeatures => {
  const currentPlan = appState.settings.current_plan;
  const merchandisingDisabled = featuresExclusive.merchandising.includes(currentPlan);
  const shoprenterBadgesDisabled = featuresExclusive.shoprenter_badges.includes(currentPlan);
  const attributeFiltersDisabled = featuresExclusive.attribute_filters.includes(currentPlan);

  return {
    merchandising: merchandisingDisabled,
    shoprenterBadges: shoprenterBadgesDisabled,
    attributeFilters: attributeFiltersDisabled,
  };
};

const getShopifyScopes = async (): Promise<Record<string, boolean>> => {
  try {
    const url = `${backendBasePaths.shopifyApi}/checkScope/${appState.storeId}`;
    const response = await fetch(url);

    return response.json();
  } catch (e) {
    console.log(e);
    return {};
  }
};

function isCurrentPlanTrial() {
  return trialPlans.includes(appState.settings.current_plan);
}

export function getIsCustomPlanWithPriceOverUnlimitedPlan(): boolean {
  const { current_plan, subscription_info } = appState.settings;
  const isShopifyCustom = getIsShopifyCustomPlan(current_plan);
  const isOverUnlimitedPrice = subscription_info?.price >= shopifyPlans.shopify_unlimited.price;
  return isShopifyCustom && isOverUnlimitedPrice;
}

function getAdminUrl({ storeId, engine, customDomain }: GetAdminUrl) {
  switch (engine) {
    case WebshopEngine.SHOPIFY: {
      const shopName = storeId.replace('.myshopify.com', '');
      return `https://admin.shopify.com/store/${shopName}`;
    }
    case WebshopEngine.SHOPRENTER:
      return `${customDomain}/admin`;
    case WebshopEngine.MAGENTO2: {
      return `https://${storeId}/admin`;
    }
    case WebshopEngine.WOOCOMMERCE: {
      return `https://${storeId}/wp-admin`;
    }
    default:
      return null;
  }
}

function getAppUrl(engine: WebshopEngine, adminUrl: string): string {
  const appUrlsByEngine = {
    [WebshopEngine.SHOPIFY]: `${adminUrl}/apps/${shopifyAppUrl}`,
    [WebshopEngine.SHOPRENTER]: `${adminUrl}/app/${shoprenterAppUrl}`,
  };

  return appUrlsByEngine[engine] ?? '';
}

function getStoreHostOrDefaultDomain(defaultDomain: string) {
  return window.location?.ancestorOrigins?.length ? window.location.ancestorOrigins[0] : defaultDomain;
}

export function isSubscriptionUnsuccessful(): boolean {
  const srSubStart = new LocalStorageWithStoreId(subscriptionLocalStorageKey).getItem();

  return !!srSubStart && moment(srSubStart).add(1, 'day').isAfter();
}

export async function checkShopifyLangAndMarketsScope(): Promise<void> {
  if (!appState.isShopify) {
    return;
  }

  const allowedScopes = await getShopifyScopes();
  appState.hasMultiLangAccess = allowedScopes.read_locales || false;
  appState.hasMarketsAccess = !!(allowedScopes.read_markets && allowedScopes.unauthenticated_read_product_listings);
}

export function checkAndSetShoprenterFirstSyncFinished(): void {
  if (!appState.isShoprenter || appState.isFirstSyncFinished) {
    return;
  }

  const settingsInterval = setInterval(async () => {
    const syncFinished = await checkAndSetSyncIsFinished();

    if (syncFinished) {
      onFirstSyncComplete();
      clearInterval(settingsInterval);
    }
  }, 5000);
}

async function checkAndSetSyncIsFinished(): Promise<boolean> {
  try {
    const settings = await getStoreSettings(appState.storeId);
    appState.updateSyncSettings(settings);
    return appState.isFirstSyncFinished;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export function getPlanHasExpired(): boolean {
  const { expiration_date } = appState.settings;
  return expiration_date && moment(expiration_date).isBefore();
}

export {
  getAdminUrl,
  getAppUrl,
  getDisabledFeatures,
  getShopifyScopes,
  getShoprenterPriceGroup,
  getStoreHostOrDefaultDomain,
  isCurrentPlanTrial,
};
