// @ts-strict-ignore
import { appState } from 'store';

import { icpCountries } from './constants';
import { isIcpPlanName } from './statelessFunctions';

export const isIcpStore = () => {
  const { isShopify, settings } = appState;
  const {
    plan_name,
    estimated_monthly_revenue_usd,
    search_stats_summary: { monthlyEstimatedSessions },
    country_code,
  } = settings;

  const isIcpPlan = isIcpPlanName(plan_name);

  const isIcpCountry = isIcpCountryCode(country_code);

  const isIcpStoreActivityQualified = isIcpStoreActivity(estimated_monthly_revenue_usd, monthlyEstimatedSessions);

  return isShopify && isIcpStoreActivityQualified && isIcpPlan && isIcpCountry;
};

function isIcpStoreActivity(estimatedMonthlyRevenue: number, estimatedMonthlySessions: number) {
  const activityThreshold = 50000;
  const isIcpMonthlyRevenue = estimatedMonthlyRevenue > activityThreshold;
  const isIcpMonthlySessions = estimatedMonthlySessions > activityThreshold;

  return isIcpMonthlyRevenue || isIcpMonthlySessions;
}

function isIcpCountryCode(countryCode: string) {
  return icpCountries.includes(countryCode);
}
