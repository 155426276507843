import React, { FC, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Admin from './Admin';
import History from './components/History/History';

const App: FC = () => (
  <BrowserRouter>
    <Suspense fallback="">
      <History />
      <Admin />
    </Suspense>
  </BrowserRouter>
);

export default App;
