import { store } from '@risingstack/react-easy-state';

import { MerchandisingRule, MerchandisingTypes } from './Merchandising.types';

export const defaultRule: MerchandisingRule = {
  id: '',
  name: '',
  selection_type: 'product',
  selected: [],
  promotion_type: 'match',
  keywords: [],
  promotion_weight: '1',
};

const merchandisingStore: MerchandisingTypes = store({
  loading: false,
  deleting: false,
  fields: [],
  datePicker: {
    overlayVisible: false,
    range: 'no_limit',
  },
  selectedOption: {
    product: [],
    stock: [],
    tag: [],
    collection: [],
  },
});

export default merchandisingStore;
