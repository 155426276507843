// @ts-strict-ignore
import 'moment/dist/locale/hu';

import authState from 'authentication/store';
import moment from 'moment';
import pricingState from 'pages/Pricing/Pricing.store';
import { getShopifyVisiblePlans } from 'pages/Pricing/ShopifyPricingPage/functions';
import { appState } from 'store';
import { ShoprenterPlan } from 'types.d';
import { setRestrictedFeatures } from 'utils/RestrictedFeatures/functions';
import { billingCycleToString, isEmbeddedAdmin } from 'utils/constants';
import { checkIfPaidPlan, getShopifyPartnersData } from 'utils/statelessFunctions';
import { initializeStatisticsRange } from 'utils/statistics/functions';

import { RapidSearchPlans, WebshopEngine } from '../enums';
import { initializeLang } from '../initializeI8next';
import invoicingState from '../pages/Invoicing/Invoicing.store';
import shoprenterPlans from '../pages/Pricing/ShoprenterPlanCard/shoprenter.plans';
import { setPlanLimitationStats } from '../utils/billing/setPlanLimitationStats';
import { getShopifyCampaignData, setSpringCampaignData } from './initialize.campaigns';
import {
  checkAndSetShoprenterFirstSyncFinished,
  checkShopifyLangAndMarketsScope,
  getAdminUrl,
  getAppUrl,
  getDisabledFeatures,
  getIsCustomPlanWithPriceOverUnlimitedPlan,
  getPlanHasExpired,
  getShoprenterPriceGroup,
  getStoreHostOrDefaultDomain,
  isCurrentPlanTrial,
  isSubscriptionUnsuccessful,
} from './initialize.functions';

const initializeGeneral = async (): Promise<void> => {
  if (appState.isMarketplace) {
    return;
  }

  const initializeInvoicingState = (): void => {
    invoicingState.companyNameFilled = !!appState.settings.subscription_billing_data?.company;
  };

  const { settings } = appState;
  const {
    webshop_engine,
    install_date,
    current_plan,
    expiration_date,
    default_language,
    shoprenter_public_settings,
    subscription_info,
  } = settings;

  const installDate = moment(install_date);
  const daysAfterInstall = moment().endOf('day').diff(installDate, 'days');

  const isShopify = webshop_engine === WebshopEngine.SHOPIFY;
  appState.isShopify = isShopify;
  const isShoprenter = webshop_engine === WebshopEngine.SHOPRENTER;
  appState.isShoprenter = isShoprenter;
  appState.useShopifyNavBar = appState.isShopify && isEmbeddedAdmin;
  appState.daysAfterInstall = daysAfterInstall;
  appState.installDate = installDate;
  appState.isTrial = isCurrentPlanTrial();
  appState.isCancelled = current_plan === RapidSearchPlans.CANCELLED;
  appState.planHasExpired = getPlanHasExpired();
  const isPaid = checkIfPaidPlan(current_plan);
  appState.isPaid = isPaid;
  appState.isFree = current_plan === RapidSearchPlans.SHOPIFY_FREE_1;
  appState.multivariateEnabled = appState.settings.multivariate_testing?.enabled ?? false;
  appState.isShoprenterOrShopify = isShopify || isShoprenter;
  authState.storeIds = appState.settings.storeIds;
  appState.isDemo = appState.settings.demo_store;
  appState.reviewAdded = appState.settings.shopify_settings.review_added === true;
  appState.isAuchan = webshop_engine === WebshopEngine.AUCHAN;
  const huStoresExceptAuchan = !isShopify && !appState.isAuchan;
  appState.shouldShowBillingPage = huStoresExceptAuchan && isPaid;
  appState.isFirstSyncFinished = !!settings.last_import;
  appState.isUnlimitedSessions = isShopify && settings.subscription_info?.monthly_sessions === 0;
  appState.isCustomUnlimitedPlan = appState.isUnlimitedSessions && getIsCustomPlanWithPriceOverUnlimitedPlan();
  appState.customDomain = getStoreHostOrDefaultDomain(appState.settings.default_domain);

  const adminUrl = getAdminUrl({
    storeId: appState.storeId,
    engine: webshop_engine,
    customDomain: appState.customDomain,
  });

  appState.adminUrl = adminUrl;
  appState.appUrl = getAppUrl(webshop_engine, adminUrl);

  let adminLang = 'hu';

  if (isShopify) {
    adminLang = 'en';
  } else if (isShoprenter) {
    adminLang = 'hu';
  } else if (default_language !== 'hu') {
    adminLang = 'en';
  }

  await initializeLang(adminLang);

  moment.locale(adminLang);

  initializeStatisticsRange();

  if (isShoprenter) {
    setSpringCampaignData();
    initializeInvoicingState();

    const priceGroup = getShoprenterPriceGroup();
    pricingState.billingDataReviewed = shoprenter_public_settings?.billing_data_reviewed;
    pricingState.shoprenterVisiblePlans = Object.keys(shoprenterPlans).filter(
      (planId) => shoprenterPlans[planId].group === priceGroup,
    ) as ShoprenterPlan[];

    appState.isShoprenterSearchLimitReached =
      isPaid && settings.search_stats_summary.searchesCount > settings.subscription_info.monthly_searches;
  }

  if (appState.planHasExpired) {
    const lastDate = moment(expiration_date).subtract(1, 'day');
    appState.setCustomDate([moment(installDate), moment(lastDate)], [installDate, lastDate]);
  }

  if (appState.isTrial || appState.isCancelled) {
    const expirationDate = expiration_date ? moment(expiration_date) : moment(installDate).add(14, 'days').endOf('day');
    appState.expirationDate = expirationDate.toDate();
  }

  const billingCycle = subscription_info?.billing_cycle;
  if (billingCycle) {
    appState.billingCycle = billingCycleToString[billingCycle];
  }

  setPlanLimitationStats();

  if (isShopify) {
    appState.campaign = getShopifyCampaignData(settings.campaign_end_date);
    pricingState.shopifyVisiblePlans = getShopifyVisiblePlans();
    getShopifyPartnersData().then((shopifyPartners) => {
      appState.shopifyPartners = shopifyPartners;
    });
  }

  appState.isUnsuccessfulSubscription = isSubscriptionUnsuccessful();
  appState.disabledFeatures = getDisabledFeatures();

  checkShopifyLangAndMarketsScope();
  setRestrictedFeatures();

  if (isShopify) {
    import(/* webpackChunkName: "getThemeList" */ './getThemeList').then((value) => {
      value.default(appState.storeId);
    });

    if (isEmbeddedAdmin) {
      import(/* webpackChunkName: "getShopifySessionToken" */ './getShopifySessionToken').then((value) => {
        value.default();
      });
    }
  }

  checkAndSetShoprenterFirstSyncFinished();
};

export default initializeGeneral;
