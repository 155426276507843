import { RapidSearchPlans } from 'enums';
import { Settings, SubscriptionInfo, SubscriptionPlan } from 'types.d';
import { Metric } from 'web-vitals/src/types/base';

export type PosthogEventProperties = {
  billing_cycle?: SubscriptionInfo['billing_cycle'];
  install_count?: Settings['install_count'];
  plan_name?: RapidSearchPlans | SubscriptionPlan;
  price?: SubscriptionInfo['price'];
  type?: SyncTypes;
  lcp_url?: string;
  lcp_element_classname?: string;
  lcp_element_inner_html?: string;
  lcp_time?: number;
  fcp_time?: number;
  lcp_rating?: Metric['rating'];
  fcp_rating?: Metric['rating'];
  $current_url?: string;
};

export enum SyncTypes {
  FirstSync = 'first_sync',
}

export enum PosthogEvents {
  SyncProgressbarFinished = 'sync_progressbar_finished',
  SubscriptionUpdate = 'subscription_update',
  LCP = 'largest_contentful_paint',
  FCP = 'first_contentful_paint',
  PageView = '$pageview',
}
