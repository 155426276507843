// @ts-strict-ignore
import { RapidSearchPlans, ShopifyPriceGroup } from 'enums';
import { ShopifyPlanDetails } from 'types.d';
import { backendBasePaths } from 'utils/constants';

type ShopifyPlanList = Partial<Record<RapidSearchPlans, ShopifyPlanDetails>>;
const normalPlanList: ShopifyPlanList = {
  [RapidSearchPlans.SHOPIFY_FREE_1]: {
    id: RapidSearchPlans.SHOPIFY_FREE_1,
    planName: 'Free',
    price: 0,
    maxNumberOfSessions: 2000,
    maxNumberOfProducts: 5000,
    isShopifyPlus: false,
    group: [ShopifyPriceGroup.NORMAL, ShopifyPriceGroup.BF_2023],
  },
  [RapidSearchPlans.BASIC_1]: {
    id: RapidSearchPlans.BASIC_1,
    planName: 'Basic 1',
    price: 9,
    maxNumberOfSessions: 5000,
    maxNumberOfProducts: 10000,
    isShopifyPlus: false,
    group: [ShopifyPriceGroup.NORMAL],
  },
  [RapidSearchPlans.BASIC_2]: {
    id: RapidSearchPlans.BASIC_2,
    planName: 'Basic 2',
    price: 19,
    maxNumberOfSessions: 12000,
    maxNumberOfProducts: 10000,
    isShopifyPlus: false,
    group: [ShopifyPriceGroup.NORMAL],
  },
  [RapidSearchPlans.BASIC_3]: {
    id: RapidSearchPlans.BASIC_3,
    planName: 'Basic 3',
    price: 39,
    maxNumberOfSessions: 25000,
    maxNumberOfProducts: 10000,
    isShopifyPlus: true,
    group: [ShopifyPriceGroup.NORMAL],
  },
  [RapidSearchPlans.PRO_1]: {
    id: RapidSearchPlans.PRO_1,
    planName: 'Pro 1',
    price: 59,
    maxNumberOfSessions: 45000,
    maxNumberOfProducts: 100000,
    isShopifyPlus: true,
    group: [ShopifyPriceGroup.NORMAL],
  },
  [RapidSearchPlans.PRO_2]: {
    id: RapidSearchPlans.PRO_2,
    planName: 'Pro 2',
    price: 79,
    maxNumberOfSessions: 75000,
    maxNumberOfProducts: 100000,
    isShopifyPlus: true,
    group: [ShopifyPriceGroup.NORMAL],
  },
  [RapidSearchPlans.PRO_3]: {
    id: RapidSearchPlans.PRO_3,
    planName: 'Pro 3',
    price: 129,
    maxNumberOfSessions: 130000,
    maxNumberOfProducts: 100000,
    isShopifyPlus: true,
    group: [ShopifyPriceGroup.NORMAL],
  },
  [RapidSearchPlans.PRO_4]: {
    id: RapidSearchPlans.PRO_4,
    planName: 'Pro 4',
    price: 199,
    maxNumberOfSessions: 500000,
    maxNumberOfProducts: 100000,
    isShopifyPlus: true,
    group: [ShopifyPriceGroup.NORMAL],
  },
  [RapidSearchPlans.SHOPIFY_UNLIMITED]: {
    id: RapidSearchPlans.SHOPIFY_UNLIMITED,
    planName: 'Enterprise',
    price: 349,
    maxNumberOfSessions: 0,
    maxNumberOfProducts: 500000,
    isShopifyPlus: true,
    group: [ShopifyPriceGroup.NORMAL],
  },
};

const campaignPlans: ShopifyPlanList = {
  [RapidSearchPlans.BF_BASIC_1]: {
    ...normalPlanList[RapidSearchPlans.BASIC_1],
    id: RapidSearchPlans.BF_BASIC_1,
    originalPrice: 9,
    price: 7,
    group: [ShopifyPriceGroup.BF_2023],
  },
  [RapidSearchPlans.BF_BASIC_2]: {
    ...normalPlanList[RapidSearchPlans.BASIC_2],
    id: RapidSearchPlans.BF_BASIC_2,
    originalPrice: 19,
    price: 15,
    group: [ShopifyPriceGroup.BF_2023],
  },
  [RapidSearchPlans.BF_BASIC_3]: {
    ...normalPlanList[RapidSearchPlans.BASIC_3],
    id: RapidSearchPlans.BF_BASIC_3,
    originalPrice: 39,
    price: 30,
    group: [ShopifyPriceGroup.BF_2023],
  },
  [RapidSearchPlans.BF_PRO_1]: {
    ...normalPlanList[RapidSearchPlans.PRO_1],
    id: RapidSearchPlans.BF_PRO_1,
    originalPrice: 59,
    price: 47,
    group: [ShopifyPriceGroup.BF_2023],
  },
  [RapidSearchPlans.BF_PRO_2]: {
    ...normalPlanList[RapidSearchPlans.PRO_2],
    id: RapidSearchPlans.BF_PRO_2,
    originalPrice: 79,
    price: 63,
    group: [ShopifyPriceGroup.BF_2023],
  },
  [RapidSearchPlans.BF_PRO_3]: {
    ...normalPlanList[RapidSearchPlans.PRO_3],
    id: RapidSearchPlans.BF_PRO_3,
    originalPrice: 129,
    price: 99,
    group: [ShopifyPriceGroup.BF_2023],
  },
  [RapidSearchPlans.BF_PRO_4]: {
    ...normalPlanList[RapidSearchPlans.PRO_4],
    id: RapidSearchPlans.BF_PRO_4,
    originalPrice: 199,
    price: 159,
    group: [ShopifyPriceGroup.BF_2023],
  },
  [RapidSearchPlans.BF_SHOPIFY_UNLIMITED]: {
    ...normalPlanList[RapidSearchPlans.SHOPIFY_UNLIMITED],
    id: RapidSearchPlans.BF_SHOPIFY_UNLIMITED,
    originalPrice: 349,
    price: 279,
    group: [ShopifyPriceGroup.BF_2023],
  },
};

export const shopifyPlans: ShopifyPlanList = {
  ...normalPlanList,
  ...campaignPlans,
};

export const trialPlan: ShopifyPlanDetails = {
  id: RapidSearchPlans.SHOPIFY_TRIAL,
  planName: 'Trial - Unlimited',
  price: 0,
  maxNumberOfSessions: 0,
  maxNumberOfProducts: 0,
  isShopifyPlus: false,
  group: [ShopifyPriceGroup.NORMAL],
};

export const newPricingApiEndpoint = `${backendBasePaths.shopifyApi}/billing/new`;
