// @ts-strict-ignore
import { store } from '@risingstack/react-easy-state';
import { PaymentType } from 'enums';
import { AutoSubscriptionType, ShopifyPlanDetails, ShoprenterPlan, SubscriptionPlan } from 'types.d';

export type PricingModeType = 'annually' | 'monthly';

type PricingState = {
  mode: PricingModeType;
  setPricingMode: (value) => void;
  subscriptionLoading: boolean;
  billingDataReviewed: boolean;
  showBillingDataReview: boolean;
  planId: SubscriptionPlan;
  shoprenterVisiblePlans?: ShoprenterPlan[];
  shopifyVisiblePlans?: ShopifyPlanDetails[];
  paymentModal: {
    step: number;
    planId?: ShoprenterPlan;
    isActive: boolean;
  };
  newPlanInfo: {
    loading: boolean;
    data?: {
      trialDays: number;
      remainingAmount: number;
      lastPaidPeriod: {
        startDate: string;
        endDate: string;
      };
      newBillingPeriod: {
        startDate: string;
        endDate: string;
      };
      remainingPeriod: {
        startDate: string;
        endDate: string;
      };
    };
  };
  overduePlanInfo: {
    loading: boolean;
    data?: {
      isOverdue: boolean;
      newSubStartDate: string;
      overdueStartDate?: string;
      overdueEndDate?: string;
      billingMonths?: number;
      price?: number;
    };
  };
  paymentMethod: PaymentType;
  customSubscriptionId?: number;
  subscriptionType: AutoSubscriptionType;
};

const pricingState: PricingState = store({
  mode: 'monthly',
  subscriptionLoading: false,
  billingDataReviewed: false,
  showBillingDataReview: false,
  planId: undefined,
  setPricingMode: (value) => {
    pricingState.mode = value;
  },
  paymentModal: {
    step: 1,
    isActive: false,
  },
  newPlanInfo: {
    loading: null,
  },
  overduePlanInfo: {
    loading: null,
  },
  paymentMethod: PaymentType.CARD,
  customSubscriptionId: null,
  subscriptionType: null,
});

export default pricingState;
