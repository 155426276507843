import { RestrictedFeatures } from 'enums';

import { getParentQueryString } from '../statelessFunctions';

export default class RestrictedFeaturesChecker {
  private readonly featureName: RestrictedFeatures;

  static isSuperAdmin = RestrictedFeaturesChecker.isFeatureEnabledByUrl(RestrictedFeatures.SUPER_ADMIN);

  constructor(featureName: RestrictedFeatures) {
    this.featureName = featureName;
  }

  isFeatureEnabled() {
    return RestrictedFeaturesChecker.isSuperAdmin || RestrictedFeaturesChecker.isFeatureEnabledByUrl(this.featureName);
  }

  private static isFeatureEnabledByUrl(featureName: RestrictedFeatures) {
    const queryParams = getParentQueryString();
    return queryParams[featureName] === '1';
  }
}
