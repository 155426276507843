import { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { appState } from '../../store';
import { generateUrlWithParams } from '../../utils/generateUrlWithParams';

const History: FC = () => {
  const history = useHistory();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const push = useCallback((path: string, state): void => {
    history.push(generateUrlWithParams(path), state);
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const replace = useCallback((path: string, state): void => {
    history.replace(generateUrlWithParams(path), state);
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    appState.history = { ...history, push, replace };
  }, []);

  return null;
};

export default History;
