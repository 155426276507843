// @ts-strict-ignore
import { basePrices, shoprenterPlanLimits } from '@rapidsearch/shared/lib/engines/shoprenter/pricing/constants';
import { PlanSettingShoprenter, ShoprenterPlan } from 'types.d';

import { ShoprenterPlanFamily, ShoprenterPriceGroup } from '../../../enums';

const baseShoprenterPlans: Record<ShoprenterPlanFamily, Omit<PlanSettingShoprenter, 'prices' | 'group'>> = {
  [ShoprenterPlanFamily.STARTER]: {
    monthly_searches: shoprenterPlanLimits[ShoprenterPlanFamily.STARTER],
    features: ['Max 5.000 termék', '5.000 keresés / hó', 'Márkajelzéssel', 'Max. 1 domain', 'Összes funkció'],
    name: 'Starter',
    family: ShoprenterPlanFamily.STARTER,
  },
  [ShoprenterPlanFamily.ADVANCED]: {
    name: 'Advanced',
    monthly_searches: shoprenterPlanLimits[ShoprenterPlanFamily.ADVANCED],
    features: ['max 20.000 termék', '30.000 keresés / hó', 'Márkajelzés nélküli', 'Max. 1 domain', 'Összes funkció'],
    family: ShoprenterPlanFamily.ADVANCED,
  },
  [ShoprenterPlanFamily.PREMIUM]: {
    name: 'Premium',
    monthly_searches: shoprenterPlanLimits[ShoprenterPlanFamily.PREMIUM],
    features: ['max 40.000 termék', '60.000 keresés / hó', 'Márkajelzés nélküli', 'Max. 1 domain', 'Összes funkció'],
    family: ShoprenterPlanFamily.PREMIUM,
  },
  [ShoprenterPlanFamily.ENTERPRISE]: {
    name: 'Enterprise',
    monthly_searches: shoprenterPlanLimits[ShoprenterPlanFamily.ENTERPRISE],
    features: ['max 50.000 termék', '150.000 keresés / hó', 'Márkajelzés nélküli', 'Max. 5 domain', 'Összes funkció'],
    family: ShoprenterPlanFamily.ENTERPRISE,
  },
};

const shoprenterPlans: Record<ShoprenterPlan, PlanSettingShoprenter> = {
  starter: {
    ...baseShoprenterPlans.starter,
    prices: {
      annually: basePrices.starter * 10, // 14
      semiannual: basePrices.starter * 5.4,
      quarterly: basePrices.starter * 3,
      monthly: basePrices.starter, // 13
    },
  },
  starter2: {
    ...baseShoprenterPlans.starter,
    prices: {
      annually: 75000, // 53
      semiannual: basePrices.starter2 * 5.4,
      quarterly: basePrices.starter2 * 3,
      monthly: basePrices.starter2, // 52
    },
    group: ShoprenterPriceGroup.BEFORE_2022_11_26,
  },
  bf_starter2: {
    ...baseShoprenterPlans.starter,
    prices: {
      annually: basePrices.bf_starter2 * 10, // 446
      semiannual: basePrices.bf_starter2 * 5.4,
      quarterly: basePrices.bf_starter2 * 3,
      monthly: basePrices.bf_starter2, // 450
    },
    former_monthly_price: 7000,
    group: ShoprenterPriceGroup.BF_2021,
  },
  starter3: {
    ...baseShoprenterPlans.starter,
    prices: {
      annually: basePrices.starter3 * 10, // 327
      semiannual: basePrices.starter3 * 5.4,
      quarterly: basePrices.starter3 * 3,
      monthly: basePrices.starter3, // 326
    },
    group: ShoprenterPriceGroup.AFTER_2022_11_26,
  },

  advanced0: {
    ...baseShoprenterPlans.advanced,
    name: 'Advanced (kedvezményes)',
    prices: {
      annually: basePrices.advanced0 * 10, // 169
      semiannual: basePrices.advanced0 * 5.4,
      quarterly: basePrices.advanced0 * 3,
      monthly: basePrices.advanced0, // 94
    },
    group: ShoprenterPriceGroup.SPECIAL,
  },
  advanced: {
    ...baseShoprenterPlans.advanced,
    prices: {
      annually: 145000, // 16
      semiannual: basePrices.advanced * 5.4,
      quarterly: basePrices.advanced * 3,
      monthly: basePrices.advanced, // 15
    },
  },
  bf_advanced2: {
    ...baseShoprenterPlans.advanced,
    prices: {
      annually: basePrices.bf_advanced2 * 10, // 447
      semiannual: basePrices.bf_advanced2 * 5.4,
      quarterly: basePrices.bf_advanced2 * 3,
      monthly: basePrices.bf_advanced2, // 451
    },
    former_monthly_price: 15000,
    group: ShoprenterPriceGroup.BF_2021,
  },
  advanced2: {
    ...baseShoprenterPlans.advanced,
    prices: {
      annually: basePrices.advanced2 * 10, // 55
      semiannual: basePrices.advanced2 * 5.4,
      quarterly: basePrices.advanced2 * 3,
      monthly: basePrices.advanced2, // 54
    },
    group: ShoprenterPriceGroup.BEFORE_2022_11_26,
  },
  advanced3: {
    ...baseShoprenterPlans.advanced,
    prices: {
      annually: basePrices.advanced3 * 10, // 329
      semiannual: basePrices.advanced3 * 5.4,
      quarterly: basePrices.advanced3 * 3,
      monthly: basePrices.advanced3, // 328
    },
    group: ShoprenterPriceGroup.AFTER_2022_11_26,
  },

  premium: {
    ...baseShoprenterPlans.premium,
    prices: {
      annually: 290000, // 51
      semiannual: basePrices.premium * 5.4,
      quarterly: basePrices.premium * 3,
      monthly: basePrices.premium, // 50
    },
  },
  premium2: {
    ...baseShoprenterPlans.premium,
    prices: {
      annually: basePrices.premium2 * 10, // 57
      semiannual: basePrices.premium2 * 5.4,
      quarterly: basePrices.premium2 * 3,
      monthly: basePrices.premium2, // 56
    },
    group: ShoprenterPriceGroup.BEFORE_2022_11_26,
  },
  bf_premium2: {
    ...baseShoprenterPlans.premium,
    prices: {
      annually: basePrices.bf_premium2 * 10, // 448
      semiannual: basePrices.bf_premium2 * 5.4,
      quarterly: basePrices.bf_premium2 * 3,
      monthly: basePrices.bf_premium2, // 452
    },
    former_monthly_price: 30000,
    group: ShoprenterPriceGroup.BF_2021,
  },
  premium3: {
    ...baseShoprenterPlans.premium,
    prices: {
      annually: basePrices.premium3 * 10, // 331
      semiannual: basePrices.premium3 * 5.4,
      quarterly: basePrices.premium3 * 3,
      monthly: basePrices.premium3, // 330
    },
    group: ShoprenterPriceGroup.AFTER_2022_11_26,
  },

  enterprise: {
    ...baseShoprenterPlans.enterprise,
    prices: {
      annually: 576000, // 18
      semiannual: basePrices.enterprise * 5.4,
      quarterly: basePrices.enterprise * 3,
      monthly: basePrices.enterprise, // 17
    },
  },
  enterprise2: {
    ...baseShoprenterPlans.enterprise,
    prices: {
      annually: 675000, // 59
      semiannual: basePrices.enterprise2 * 5.4,
      quarterly: basePrices.enterprise2 * 3,
      monthly: basePrices.enterprise2, // 58
    },
    group: ShoprenterPriceGroup.BEFORE_2022_11_26,
  },
  bf_enterprise2: {
    ...baseShoprenterPlans.enterprise,
    prices: {
      annually: basePrices.bf_enterprise2 * 10, // 449
      semiannual: basePrices.bf_enterprise2 * 5.4,
      quarterly: basePrices.bf_enterprise2 * 3,
      monthly: basePrices.bf_enterprise2, // 453
    },
    former_monthly_price: 70000,
    group: ShoprenterPriceGroup.BF_2021,
  },
  enterprise3: {
    ...baseShoprenterPlans.enterprise,
    prices: {
      annually: basePrices.enterprise3 * 10, // 333
      semiannual: basePrices.enterprise3 * 5.4,
      quarterly: basePrices.enterprise3 * 3,
      monthly: basePrices.enterprise3, // 332
    },
    group: ShoprenterPriceGroup.AFTER_2022_11_26,
  },
  'enterprise-child': {
    ...baseShoprenterPlans.enterprise,
    name: 'Enterprise',
    prices: {
      annually: 0,
      semiannual: 0,
      quarterly: 0,
      monthly: 0,
    },
    monthly_searches: 0,
    group: ShoprenterPriceGroup.SPECIAL,
  },
};

export default shoprenterPlans;
