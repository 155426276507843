import { from } from 'env-var';

const env = from(import.meta.env);

const isDev = import.meta.env.MODE === 'development';
const isProd = import.meta.env.MODE === 'production';

export const config = {
  adminDomain: env.get('VITE_ADMIN_DOMAIN').required().asString(),
  crispWebId: env.get('VITE_CRISP_WEB_ID').required(isProd).asString(),
  esDomain: env.get('VITE_ES_DOMAIN').required().asString(),
  firebaseApiKey: env.get('VITE_FIREBASE_API_KEY').required().asString(),
  ga4MeasurementId: env.get('VITE_GA4_MEASUREMENT_ID').required().asString(),
  shopifyAdminDomain: env.get('VITE_SHOPIFY_ADMIN_DOMAIN').required().asString(),
  shopifyAppUrl: env.get('VITE_SHOPIFY_APP_URL').required().asString(),
  shopifyExtensionId: env.get('VITE_SHOPIFY_EXTENSION_ID').asString(),
  shoprenterAdminDomain: env.get('VITE_SHOPRENTER_ADMIN_DOMAIN').required().asString(),
  shoprenterAppUrl: env.get('VITE_SHOPRENTER_APP_ID').required().asString(),
  staticDomain: env.get('VITE_STATIC_DOMAIN').required().asString(),
  shopifyAssetsCacheDomain: env.get('VITE_SHOPIFY_ASSETS_CACHE_DOMAIN').required().asString(),
  livePosthogKey: env.get('VITE_LIVE_POSTHOG_KEY').asString(),
  testPosthogKey: env.get('VITE_TEST_POSTHOG_KEY').asString(),
  isDev,
  isProd,
};
