// @ts-strict-ignore
import authState from 'authentication/store';
import { AdminRoles, WebshopEngine } from 'enums';
import cloneDeep from 'lodash/cloneDeep';
import LocalStorage from 'utils/LocalStorage/LocalStorage';

import merchandisingStore from '../pages/Settings/Merchandising/Merchandising.store';
import synonymsState from '../pages/Settings/Synonyms/Synonyms.store';
import { appState } from '../store';
import { Settings } from '../types.d';
import { shopDomainLocalStorageKey } from '../utils/constants';
import { getStoreSettings } from '../utils/getStoreSettings';
import initializeGeneral from './initializeGeneral';

async function initializeStoreSettings(storeId: string): Promise<void> {
  const storeSettings = window.storeSettings ? window.storeSettings : await getStoreSettings(storeId);
  await setInitialSettings(storeSettings, storeId);
}

async function setInitialSettings(settings: Settings, storeId): Promise<void> {
  appState.settings = settings;
  appState.storeId = storeId;
  appState.cachedData = {};
  await initializeGeneral();

  new LocalStorage(shopDomainLocalStorageKey).setItem(storeId);

  appState.originalSettings = cloneDeep(settings);
  synonymsState.synonyms = (appState.settings.synonyms || []).reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.id]: currentValue,
    }),
    {},
  );
  merchandisingStore.rules = appState.settings.merchandising;
  appState.isSuperAdmin = authState.role === AdminRoles.SUPER_ADMIN;
  appState.isMagento2SuperAdmin = settings.webshop_engine === WebshopEngine.MAGENTO2 && appState.isSuperAdmin;
  appState.storefrontPreviewDomain = `${settings.default_domain}?rs_preview=1`;
}

export { initializeStoreSettings, setInitialSettings };
