import React, { FC } from 'react';

const Loading: FC<{ className?: string }> = ({ className }) => (
  <div className={`${className} rs_spinner_wrapper`}>
    <div className="rs_spinner">
      <div className="rs_bounce_1" />
      <div className="rs_bounce_2" />
      <div className="rs_bounce_3" />
    </div>
  </div>
);

export default Loading;
