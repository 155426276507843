import { store } from '@risingstack/react-easy-state';
import { ChangeEventHandler } from 'react';
import { Synonym } from 'types.d';

export type SynonymsState = {
  id: string;
  keyword: string;
  synonyms: Record<string, Synonym>;
  type: 'synonyms' | 'one_way_synonyms';
  modalVisible: boolean;
  tags: Record<string, string>;
  synonymField: string;
  searchKeyword: string;
  handleSearchKeywordChange: ChangeEventHandler<HTMLInputElement>;
  handleClearSearchKeyword: () => void;
};

const synonymsState: SynonymsState = store({
  id: '',
  keyword: '',
  tags: {},
  synonyms: {},
  modalVisible: false,
  type: 'synonyms',
  synonymField: '',
  searchKeyword: '',
  handleSearchKeywordChange: (event) => {
    synonymsState.searchKeyword = event.target.value;
  },
  handleClearSearchKeyword: () => {
    synonymsState.searchKeyword = '';
  },
});

export default synonymsState;
